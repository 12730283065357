import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const DonationForm = ({ className: _className, copy, formId }) => {
  const iframe = `<iframe src="https://givebutter.com/embed/c/${formId}" width="100%" height="1500px" name="givebutter" frameborder="0" scrolling="no" seamless allowpaymentrequest></iframe><script src="https://givebutter.com/js/widget.js"></script>`;

  return (
    <section className={classNames("py-20 md:py-28", _className)}>
      <Container>
        <div className="flex flex-wrap gap-12 md:gap-20">
          {copy && (
            <div className="w-full md:w-2/5">
              <Text richText>{copy}</Text>
            </div>
          )}
          {formId && (
            <div
              className="flex-1 overflow-y-auto"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: iframe }}
            />
          )}
        </div>
      </Container>
    </section>
  );
};

DonationForm.defaultProps = {};

export default DonationForm;
